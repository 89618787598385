//@flow

import { DiscoveredVia, type DiscoveredViaType } from '@dt/horizon-api';
import React, { type Element, PureComponent } from 'react';

import ApigeeLogo from './svg/apigee_logo.svg';
import AwsLogo from './svg/aws_logo.svg';
import AxwayLogo from './svg/axway_logo.svg';
import AzureLogo from './svg/azure_logo.svg';
import CertTransLogo from './svg/certif_transparency_logo.svg';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import GcpLogo from './svg/gcp_logo.svg';
import IpRangeIcon from '@material-ui/icons/Router';
import KongLogo from './svg/kong_logo.svg';
import ManualUploadIcon from '@material-ui/icons/CloudUpload';
import MobileAppIcon from '@material-ui/icons/DeveloperMode';
import MobileAppStoreLogo from './svg/mobile_app_store_logo.svg';
import MulesoftLogo from './svg/mulesoft_logo.svg';
import RESTfulApiIcon from '@material-ui/icons/DeviceHub';
import WebAppAnalyzerIcon from '@material-ui/icons/Dvr';
import WebsiteCrawlerIcon from '@material-ui/icons/Pageview';

const SwaggerHubLogo =
  'https://prod-horizon.firebaseapp.com/assets/images/icons/SWAGGER_HUB.svg';

type Props = {| discovered_via: DiscoveredViaType, size: number |};

const DiscoveredViaIconMap: $Exact<
  $ObjMap<
    typeof DiscoveredVia,
    <V>() => (props: Props, alt: string) => Element<*>,
  >,
> = {
  [DiscoveredVia.AMAZON_WEB_SERVICES]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={AwsLogo} alt={alt} />
  ),
  [DiscoveredVia.APIGEE]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={ApigeeLogo} alt={alt} />
  ),
  [DiscoveredVia.APIGEE_ON_PREM]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={ApigeeLogo} alt={alt} />
  ),
  [DiscoveredVia.APPLICATION_FINGERPRINTING]: (
    { size }: Props,
    alt: string,
  ) => <FingerprintIcon style={{ height: size, width: size }} alt={alt} />,
  [DiscoveredVia.AXWAY]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={AxwayLogo} alt={alt} />
  ),
  [DiscoveredVia.AZURE]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={AzureLogo} alt={alt} />
  ),
  [DiscoveredVia.CERTIFICATE_TRANSPARENCY]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={CertTransLogo} alt={alt} />
  ),
  [DiscoveredVia.GOOGLE_CLOUD_PLATFORM]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={GcpLogo} alt={alt} />
  ),
  [DiscoveredVia.KONG]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={KongLogo} alt={alt} />
  ),
  [DiscoveredVia.MANUAL_IMPORT]: ({ size }: Props, alt: string) => (
    <ManualUploadIcon style={{ height: size, width: size }} alt={alt} />
  ),
  [DiscoveredVia.MOBILE_APP_SCANS]: ({ size }: Props, alt: string) => (
    <MobileAppIcon style={{ height: size, width: size }} alt={alt} />
  ),
  [DiscoveredVia.MOBILE_APP_STORE]: ({ size }: Props, alt: string) => (
    <MobileAppStoreLogo style={{ height: size, width: size }} alt={alt} />
  ),
  [DiscoveredVia.MULESOFT]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={MulesoftLogo} alt={alt} />
  ),
  [DiscoveredVia.RESTFUL_API_DISCOVERER]: ({ size }: Props, alt: string) => (
    <RESTfulApiIcon style={{ height: size, width: size }} alt={alt} />
  ),
  [DiscoveredVia.REVERSE_IP_LOOKUP]: ({ size }: Props, alt: string) => (
    <IpRangeIcon style={{ height: size, width: size }} alt={alt} />
  ),
  // This is also related to certificates -> "domain names in SAN"
  [DiscoveredVia.SAN_EXTRACTION]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={CertTransLogo} alt={alt} />
  ),
  [DiscoveredVia.SWAGGER_HUB]: ({ size }: Props, alt: string) => (
    <img height={size} width={size} src={SwaggerHubLogo} alt={alt} />
  ),
  [DiscoveredVia.WEBSITE_CRAWLER]: ({ size }: Props, alt: string) => (
    <WebsiteCrawlerIcon style={{ height: size, width: size }} alt={alt} />
  ),
  [DiscoveredVia.WEB_APPLICATION_SCANS]: ({ size }: Props, alt: string) => (
    <WebAppAnalyzerIcon style={{ height: size, width: size }} alt={alt} />
  ),
};

export default class extends PureComponent<Props> {
  render() {
    const { discovered_via } = this.props;

    if (!DiscoveredViaIconMap[discovered_via]) {
      throw new Error(
        `Missing discovered via icon for ${discovered_via}, DiscoveredViaType is likely out of date`,
      );
    }

    return DiscoveredViaIconMap[discovered_via](this.props, `icon`);
  }
}
