// @flow

import { DiscoveredVia, type DiscoveredViaType } from '@dt/horizon-api';

import React from 'react';
import Tag from './Tag';
// Logos and icons
import TagDiscoveredViaIcon from './TagDiscoveredViaIcon';

const DiscoveredViaStringMap: $ObjMap<typeof DiscoveredVia, <V>() => string> = {
  [DiscoveredVia.AMAZON_WEB_SERVICES]: 'AWS Analyzer',
  [DiscoveredVia.GOOGLE_CLOUD_PLATFORM]: 'GCP Analyzer',
  [DiscoveredVia.APIGEE]: 'Apigee Analyzer',
  [DiscoveredVia.AZURE]: 'Azure Analyzer',
  [DiscoveredVia.MULESOFT]: 'Mulesoft Analyzer',
  [DiscoveredVia.KONG]: 'Kong Analyzer',
  [DiscoveredVia.MANUAL_IMPORT]: 'Manual Import',
  [DiscoveredVia.MOBILE_APP_SCANS]: 'Reverse Engineering (Mobile)',
  [DiscoveredVia.REVERSE_IP_LOOKUP]: 'Reverse IP Lookup',
  [DiscoveredVia.WEB_APPLICATION_SCANS]: 'Reverse Engineering (WebApps)',
  [DiscoveredVia.CERTIFICATE_TRANSPARENCY]: 'DT Blackbox Analyzer',
  [DiscoveredVia.SAN_EXTRACTION]: 'DT Blackbox Analyzer',
  [DiscoveredVia.APIGEE_ON_PREM]: 'Apigee Analyzer',
  [DiscoveredVia.RESTFUL_API_DISCOVERER]: 'DT Blackbox Analyzer',
  [DiscoveredVia.WEBSITE_CRAWLER]: 'Website Crawler',
  [DiscoveredVia.APPLICATION_FINGERPRINTING]: 'DT Blackbox Analyzer',
  [DiscoveredVia.AXWAY]: 'Axway Analyzer',
  [DiscoveredVia.SWAGGER_HUB]: 'Swagger Hub',
  [DiscoveredVia.MOBILE_APP_STORE]: 'Mobile App Store',
};

export const getStringFromDiscoveredVia = (
  discovered_via: DiscoveredViaType,
) => {
  const discovered_via_string = DiscoveredViaStringMap[discovered_via];
  if (!discovered_via_string) {
    throw new Error(
      `Missing discovered via string for ${discovered_via}, DiscoveredViaType is likely out of date`,
    );
  }
  return discovered_via_string;
};

type Props = {|
  +discovered_via: DiscoveredViaType,
  +discovered_date?: string,
  +variant?: 'small' | 'large',
|};

const TagDiscoveredVia = ({
  discovered_via,
  discovered_date,
  variant = 'small',
}: Props) => {
  const dateString = discovered_date
    ? ` on ${new Date(discovered_date).toLocaleDateString()}`
    : '';

  return (
    <Tag
      label={`${
        variant === 'large' ? 'Discovered via ' : ''
      }${getStringFromDiscoveredVia(discovered_via)}${dateString}`}
      icon={<TagDiscoveredViaIcon discovered_via={discovered_via} size={14} />}
    />
  );
};

export default React.memo<Props>(TagDiscoveredVia);
